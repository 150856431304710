import { dispatchUser } from '../../../../../pages/user/login/redux/method'
import axios from '../../../../../utils/axios'
import actionType from './action'


export const getNotificationData = () => async dispatch => {
    let response = await axios.get(`/notification`)
    dispatch({ type: actionType.GET_NOTIFICATION_DATA, payload: response.data.response.data })
}

export const getSocietyList = () => async dispatch => {
    let response = await axios.get(`/societiesByMember`)
    dispatch({ type: actionType.GET_SOCIETY_LIST, payload: response.data.response.data })
}

export const setSocietyId = (id) => async dispatch => {
    let response = await axios.get(`/userSocietyToken/${id}`)
    dispatch(dispatchUser(response.data.response.data))

    dispatch({ type: actionType.SET_SOCIETY_ID, payload: id })
}
