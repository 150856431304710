import React from 'react'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createHashHistory } from 'history'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import Localization from './components/LayoutComponents/Localization'
import withClearCache from "./components/ClearCache";

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
//const thunkMiddleware = thunk()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development' && true) {
    middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)
//thunkMiddleware.run(thunk)



const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}


function MainApp(props) {
    return (
        <div>
            <Provider store={store}>
                <Localization>
                    <Router history={history} />
                </Localization>
            </Provider>
        </div>
    );
  }





export { App, store, history }
