export function getLeftMenuData() {
  let user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)
  user = JSON.parse(user)
  //   console.log(user)
  if (user && user.user.paymentDue) {
    const adminMenu = [
      {
        title: 'Dashboard',
        key: 'home',
        url: '/dashboard/home',
        icon: 'icmn icmn-home',
      },
      {
        title: 'Society Balance Sheet',
        key: 'societyBalance',
        url: '/societyBalance',
        icon: 'icmn icmn-file-excel',
      },
      {
        title: 'Members',
        key: 'members',
        url: '/members',
        icon: 'icmn icmn-users',
      },
      {
        title: 'Transactions',
        key: 'transactions',
        url: '/transactions',
        icon: 'icmn icmn-stats-dots',
      },
      {
        title: 'Register',
        key: 'register',
        url: '/register',
        icon: 'icmn icmn-book',
      },
    ]
    return adminMenu
  }
  const adminMenu = [
    {
      title: 'Dashboard',
      key: 'home',
      url: '/dashboard/home',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Accounts',
      key: 'accounts',
      url: '/accounts',
      icon: 'icmn icmn-briefcase',
    },
    {
      title: 'Society Balance Sheet',
      key: 'societyBalance',
      url: '/societyBalance',
      icon: 'icmn icmn-file-excel',
    },

    {
      title: 'Loans',
      key: 'loans',
      url: '/loans',
      icon: 'icmn icmn-clipboard',
    },
    {
      title: 'Members',
      key: 'members',
      url: '/members',
      icon: 'icmn icmn-users',
    },
    {
      title: 'Banks',
      key: 'banks',
      url: '/banks',
      icon: 'icmn icmn-library',
    },
    {
      title: 'Transactions',
      key: 'transactions',
      url: '/transactions',
      icon: 'icmn icmn-stats-dots',
    },
    {
      title: 'Accounts Register',
      key: 'register',
      url: '/register',
      icon: 'icmn icmn-book',
    },
  ]
  return adminMenu

  // }
}
