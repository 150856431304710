import axios from '../../../utils/axios'
import { callApi } from '../../../utils/axios'
import actionType from './action'


export const getHomeData = () => async dispatch => {
    return callApi(async () => {
        let response = await axios.get(`/member/stats`)
        dispatch({ type: actionType.GET_STATS, payload: response.data.response })
    })
}
