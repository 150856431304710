import { notification } from 'antd'
import moment from 'moment'
import actionType from './actions'
import axios from '../../../../utils/axios'

export const loginData = (data) => async (dispatch, getState) => {
  data.isDashboard = true
  window.localStorage.removeItem(window.SITE_CONFIG.STORAGE.TOKEN)
  axios
    .post(`members/login`, data)
    .then((response) => {
      const res = response.data.response.data
      // const token = res.token;

      // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(res))
      // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, token)

      // dispatch({ type: actionType.EMP_LOGIN, payload: { ...res, authorized: true } })
      // dispatch({ type: "menu/GET_DATA" })

      dispatch(dispatchUser(res))
    })
    .catch((err) => {
      const options = {
        message: 'Login failed',
        description:
          'Either mobile or password is incorrect. If you are still seeing the issue, Please clear cache and try again. Use Ctrl+Shift+Del to delete cache.',
      }
      if (err.response) options.description = err.response.data.error[0].msg
      notification.error(options)
    })
}

export const dispatchUser = (userData) => (dispatch) => {
  if (userData.token) {
    const data = []
    const upDate = moment().add(12, 'hours')
    data.push(upDate)
    window.localStorage.setItem(window.SITE_CONFIG.STORAGE.ADMIN_EXPIRY_DATE, JSON.stringify(data))
    window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(userData))
    window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, userData.token)

    dispatch({ type: actionType.EMP_LOGIN, payload: { ...userData, authorized: true } })
    dispatch({ type: 'menu/GET_DATA' })
  } else dispatch({ type: actionType.EMP_LOGIN, payload: { authorized: false } })
}

export const checkLoginData = () => (dispatch) => {
  const values = JSON.parse(localStorage.getItem(window.SITE_CONFIG.STORAGE.ADMIN_EXPIRY_DATE))

  // check "my hour" index here
  if (values[1] < moment()) {
    localStorage.removeItem(window.SITE_CONFIG.STORAGE.USER)
    // return <Redirect to="/user/login" />
  }
  const user = localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)
  if (user) {
    dispatch({ type: actionType.EMP_LOGIN, payload: { ...JSON.parse(user), authorized: true } })
  }
}

export const verifyEmail = (token) => async (dispatch) => {
  axios
    .get(`/organisation/verifyEmail?token=${token}`)
    .then((response) => {
      notification.success({
        message: 'Email verified successfully',
        description: 'You can login now.',
      })
    })
    .catch((err) => {
      const options = {
        message: 'Email verification fail',
        description: err.message,
      }
      if (err.response) options.description = err.response.data.error[0].msg
      notification.error(options)
    })
}

export const getByToken = (token, nextPage) => async (dispatch) => {
  axios
    .get(`/employee/getByToken`, { headers: { Authorization: `bearer ${token}` } })
    .then((response) => {
      const res = response.data.response.data

      dispatch(dispatchUser(res))

      // const token = res.token;

      // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.USER, JSON.stringify(res))
      // window.localStorage.setItem(window.SITE_CONFIG.STORAGE.TOKEN, token)

      // dispatch({ type: actionType.EMP_LOGIN, payload: { ...res, authorized: true } })
      // dispatch({ type: "menu/GET_DATA" })
      if (nextPage) history.push(nextPage)
    })
    .catch((err) => {
      const options = {
        message: 'Login failed',
        description: err.message,
      }
      if (err.response) options.description = err.response.data.error[0].msg
      notification.error(options)
    })
}
