import React from 'react'
import { Select } from 'antd'
import { getNotificationData , getSocietyList, setSocietyId} from './Redux/method'
import { connect } from 'react-redux'
import { getHomeData } from '../../../../pages/home/Redux/method'
import { withRouter } from 'react-router-dom'
import { notification } from 'antd'



@connect()
class HomeMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        societyList: [],
        selectedSociety : JSON.parse(localStorage.getItem(window.SITE_CONFIG.STORAGE.USER)).user.defaultSociety
    }
  }
  async componentDidMount() {
    await this.props.dispatch(getSocietyList())
    this.props.dispatch(setSocietyId(this.state.selectedSociety))
  }
  handleSocietySubmit= async(val)=>{
    this.setState({selectedSociety : val})
    await this.props.dispatch(setSocietyId(val))
    // await this.props.dispatch(getHomeData())
    // this.props.history.push(`/dashboard/home`);
    notification.success({
      message: 'Dashboard Updated',
      description: "Dashboard Updated"
    });
    window.location.reload()

  }

  render() {
    // const menu = (
    //   <Menu selectable={false} className={styles.activity}>
    //     {this.props.homeMenu.data.map((item, i) => (
    //       <Menu.Item className={styles.item} key={i}>
    //         <i className={`${styles.icon} icmn-star-full`} />
    //         <div className={styles.inner}>
    //           <div className={styles.title}>
    //             <span className="pull-right">{moment(item.createdAt).fromNow()}</span>
    //             <span className={classNames("text-capitalize", { "text-blue-500": item.level == "INFO", "text-red-500": item.level == "ERROR", "text-yellow-500": item.level == "WARN" })}>
    //               {(item.level == "INFO") ? "Info" : (item.level == "ERROR") ? "Important" : (item.level == "WARN") ? "Warning" : item.level}
    //             </span>
    //           </div>
    //           <div className={styles.descr + " whitespace-pre-wrap"}>
    //             {item.msg}
    //           </div>
    //         </div>
    //       </Menu.Item>
    //     ))}
    //   </Menu>
    // )
    return (
      <>
      <b style={{color : "slategrey"}}>Society :</b>&nbsp;<Select 
              style={{ minWidth: (window.innerWidth<800?"170px":"220px"), maxWidth: window.innerWidth<800?"170px":"220px" }} onChange={this.handleSocietySubmit} 
              defaultValue = {this.state.selectedSociety}
        >
          { this.props.homeMenu && this.props.homeMenu.societyList.map(society => (
            <Select.Option key={society._id} value={society._id}>{society.orgName}</Select.Option>
          ))}         
        </Select>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return { homeMenu: state.homeMenu }
}

export default withRouter(connect(mapStateToProps, { getNotificationData, getSocietyList })(HomeMenu))
