import React from 'react'
import { Button, Select, Input, notification } from 'antd'
import styles from './style.module.scss'
import axios, { callApi } from '../../../utils/axios'
import { connect } from 'react-redux'

@connect(({ user }) => ({ user }))
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        plans : "",
        recommended : "",
        selectedPlan : "",
        months : 1 ,
        coupon : ""
    };
  }

  selectPlan = (val) =>{
    this.setState({selectedPlan : val})
  }

  async componentDidMount() {
    return callApi(async () => {
        let response = await axios.get(`/getSocietyPlans`)
        this.setState({
            plans: response.data.response.data,
            recommended : response.data.response.recommended
        })
    })
}
loadPaymentGateway = (src)=>{
  return new Promise((resolve)=>{
    const script = document.createElement('script')
    script.src = src
    document.body.appendChild(script)
    script.onload = () =>{
      resolve(true)
    } 
    script.onerror = () =>{
      resolve(false)
    }
  })
}

displayPaymentGateway=async ()=>{
  if(this.state.selectedPlan==="" || this.state.selectedPlan<=0) {
    alert("Please select a Plan !")
    return true;
  }
  const script = await this.loadPaymentGateway("https://checkout.razorpay.com/v1/checkout.js")

  if(!script) {
    alert('Payment Gateway failed to load. Please check your connection and try again.')
  }
  try {
    let response = await axios.post(`/createOrder`, {id : this.state.selectedPlan, coupon :this.state.coupon})
    let data = response.data.response.data

  var options = {
    "key": process.env.REACT_APP_SECRET_KEY,    
     // Enter the Key ID generated from the Dashboard
    "amount": data.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": data.currency,
    "name": "Samiti Membership",
    "qty" : this.state.months,
    "description": "Thank You for your interest !",
    "image": "resources/images/logo.png",
    "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response){
        window.location.reload()
        notification.success({
          message : "Payment Successful",
          description : "Payment received. Your Plan will be updated soon"
        })
    },
    "prefill": {
        "name": `${this.props.user.user.firstName} ${this.props.user.user.lastName}`,
        "email": `${this.props.user.user.email || ""}`,
        "contact": `${this.props.user.user.phone}`
    },
    "theme": {
        "color": "#3399cc"
    }
  }
    var paymentObject = new window.Razorpay(options);
    paymentObject.open()
    paymentObject.on('payment.failed', function (response){  
      window.location.reload()
      notification.error({
        message : "Payment Failed",
        description : "Payment Failed. Reason : "+response.error.reason
      })
});
  } catch (e) {
    notification.error({
      message : "Payment Request Failed",
      description : err.response?err.response.data.error[0].msg:"Request is not valid. Please try again !"
    })
  }
}
  
  render() {
    const { user } = this.props
    return (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className="row">
          { this.props.user.user.role == window.SITE_CONFIG.USER_ROLE.SUPER_ADMIN && 
          <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="row" style={{display: "flex", flexWrap : "nowrap", flexDirection: "row", justifyContent:"space-evenly"}}>
          <div className="col-sm-4 col-md-4 col-lg-4">
            <Select
                placeholder="Plans"
                onChange={this.selectPlan}
                optionFilterProp="children"
                style={{ minWidth: "150px", fontWeight: "bold" }}
            >
                {this.state.plans && this.state.plans.map((item, i) => (
                    <Select.Option key={`${item.tier_id}`} value={item.tier_id}>{`INR ${item.price}/ ${item.tier}`}</Select.Option>
                ))
                }
            </Select>
          </div>
          <div className="col-sm-8 col-md-8 col-lg-8">
              <Input placeholder="Coupon (if any)"  allowClear name="coupon" type="text" style={window.innerWidth<800?{width:120, padding:4}:{width:"auto"}} 
              onChange={(val)=>this.setState({coupon : val.target.value})} />
          </div>
          </div>
          <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12" style={{textAlign : '-webkit-center', verticalAlign:"bottom"}}>
              <Button type="danger" size="medium" onClick={this.displayPaymentGateway}>Purchase</Button>
          </div>
         </div>
         <br/>   
        </div>
  }
          <div className="col-sm-4" style={{borderRight : "1px solid", borderLeft : "1px solid",
           border: window.innerWidth<700?"1px solid":"1px 0 1px 0" }}>
            Support : <a
              href="mailto:care.samiti@gmail.com"
              rel="noopener noreferrer"
              className="mr-4"
            >
              care.samiti@gmail.com
            </a>
            <br />
            Contact : +91-8010776684
            <br />
            Website : samiti-ui.web.app
          </div>
          { this.props.user.user.role !== window.SITE_CONFIG.USER_ROLE.SUPER_ADMIN &&
          <div className="col-sm-4"></div>
  }
          <div className="col-sm-4">
            <div className={styles.copyright}>
              <img
                src="resources/images/logo.png"
                rel="noopener noreferrer"
                alt="Samiti"
              />
              <span>
                © 2021{' '}
                <a href="#" rel="noopener noreferrer">
                  Samiti
                </a>
                <br />
                All rights reserved
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
  }
}

export default Footer
