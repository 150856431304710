import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCAy8b3q_JCN0QTXF_Jey8MUKU37qfq5tM",
  authDomain: "samiti-ui.firebaseapp.com",
  projectId: "samiti-ui",
  storageBucket: "samiti-ui.appspot.com",
  messagingSenderId: "720472628584",
  appId: "1:720472628584:web:710ccf95b374563bda8add",
  measurementId: "G-9MMFMYWDEZ"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebase.auth

export function setLanguageCode() {
  firebase.auth().useDeviceLanguage()
}

export default firebase

export async function loginWithPhone(phoneNumber) {
  let appVerifier= new firebase.auth.RecaptchaVerifier('recaptcha', {
    'size': 'invisible',
    'callback': (response) => {
    }
  });
  await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      let code = window.prompt("Please Enter OTP !")
      confirmationResult.confirm(code).then((result) => {
        // User signed in successfully.
        const user = result.user;
        // ...
      }).catch((error) => {
        console.log(error)
        // User couldn't sign in (bad verification code?)
        // ...
      });
    
      // ...
    }).catch((error) => {
      console.log(error)
      // Error; SMS not sent
      // ...
    });
  }




export async function login(email, password) {
  return firebaseAuth()
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.erroring({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = auth.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        resolve(user)
      }, reject)
    })
  }
  return getCurrentUser(firebaseAuth())
}

export async function logout() {
  return firebaseAuth()
    .signOut()
    .then(() => true)
}
