import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import menu from './menu/reducers'
import settings from './settings/reducers'
import { LoginReducer } from '../pages/user/login/index'
//import StatsReducer from '../pages/dashboard/home/Redux/reducer'
import ForgotPassword from '../pages/user/forgot/redux/reducers'
import Home from '../pages/home/Redux/reducer'
import HomeMenu from '../components/LayoutComponents/TopBar/HomeMenu/Redux/reducer'


export default (history) =>
  combineReducers({
    router: connectRouter(history),
    // user,
    menu,
    settings,
    user: LoginReducer,
    //stats: StatsReducer,
    forgotPassword: ForgotPassword,
    home: Home,
    homeMenu: HomeMenu,
  })
