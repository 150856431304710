import React, { Component } from 'react'
import qs from 'qs'
import { Input, Button, Card, Form, Select, notification } from 'antd';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import axios from '../../../utils/axios'
import { loginData } from './redux/method'
import styles from './style.module.scss'
import { Link } from 'react-router-dom';
import firebase from '../../../services/user'
// @connect(({ user }) => ({ user }))

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        countryCode: "",
        phone: "",
        password: "",
        code: ""
      },
      otp: false,
      confirmationResult: ""
    };
  }


  handleChangeInput = (e) => {
    let fields = this.state.fields
    fields[e.target.id] = e.target.value;
    this.setState({
      fields
    })
  }

  handleNumberChange = (value) => {
    this.setState({ countryCode: value })
  }
  componentDidMount() {
    var query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  }

  sendOTP = async () => {
    if (this.state.fields.phone != "" && this.state.countryCode != "" && this.state.fields.phone.length === 10) {
      this.setState({ loading: true })
      let exist = true;
      try {
        let response = await axios.get(`/isMemberExist/${this.state.fields.phone}`)
        exist = response.data.response.data
      } catch (e) {

        this.setState({ loading: false })
        notification.error({
          message: 'Login Failed.',
          description: "Cannot fetch member details !"
        });
        return true
      }

      if (!exist) {
        notification.error({
          message: 'Login Failed.',
          description: "No member found with this number !"
        });
        return true
      }
      let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha',
        {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          }
        })
      await firebase.auth().signInWithPhoneNumber(this.state.countryCode + this.state.fields.phone, appVerifier)
        // loginWithPhone(this.state.countryCode+this.state.fields.phone)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.setState({ confirmationResult, otp: true, loading: false })
          appVerifier.clear()
          document.getElementById("recaptcha-container").innerHTML = "<div id='recaptcha'></div>";
        }).catch((error) => {
          this.setState({ loading: false })
          appVerifier.clear()
          document.getElementById("recaptcha-container").innerHTML = "<div id='recaptcha'></div>";
          notification.error({
            message: 'Login Failed.',
            description: "Verification error. Please wait for some time or try again with password !"
          });
          return true
        }).catch((error) => {
          this.setState({ loading: false })
          appVerifier.clear()
          document.getElementById("recaptcha-container").innerHTML = "<div id='recaptcha'></div>";
          notification.error({
            message: 'Login Failed.',
            description: "Please wait for some time or try again with password !"
          });
          return true
        });
    } else {
      this.setState({ loading: false })
      window.alert("Please enter valid mobile and country code");
      return true;
    }
  }

  verifyOTP = (val) => {
    let code = this.state.fields.code
    this.setState({ loading: true })
    if (!code) {
      alert("Please Enter OTP")
      this.setState({ loading: false })
      return true
    }
    let confirmationResult = this.state.confirmationResult
    confirmationResult.confirm(code).then((result) => {
      // User signed in successfully.
      val.countryCode = this.state.countryCode
      val.firebase = true
      notification.success({
        message: "OTP verified",
        description: "Please wait while we log you in !"
      })
      this.props.loginData(val)
      // ...
    }).catch(error => {
      this.setState({ loading: false })
      notification.error({
        message: "Login Failed",
        description: "OTP Error. Please try again!"
      })
    })
  }

  render() {
    let a = []
    a.push({
      label: "India",
      value: "+91"
    })

    const prefixSelector = (
      <Select
        showSearch
        placeholder="Country"
        onChange={this.handleNumberChange}
        optionFilterProp="children"
        style={{ minWidth: "100px" }}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {a.map((item, i) => (
          <Option key={item.label} value={item.value}>{`${item.label} (${item.value})`}</Option>
        ))
        }
      </Select>
    );

    return (
      <div className="">
        <Helmet>
          <title>Login</title>
          <meta name="Samiti" content="Samiti" />
        </Helmet>
        <img rel="icon" className={styles.login_logo} alt="Samiti" type="image/png" src="/resources/images/logo.png" />
        <Card title={<div className="text-center">Login</div>}>
          <Form layout="vertical" hideRequiredMark onFinish={this.state.otp ? this.verifyOTP : this.props.loginData}>
            <Form.Item label="Mobile" name="phone" rules={[{
              required: true, whitespace: true, message: 'Please input registered mobile number!',
            }, {
              pattern: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{3})(( x| ext)\d{1,5}){0,1}$/, message: 'Please input valid Mobile Number',
            },
            ({ getFieldValue }) => ({
              validator: (rule, value) => {
                if (this.state.countryCode && this.state.countryCode.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject('Please select Country code!');
              },
            })]} >

              <Input placeholder="Please input your registered mobile number" addonBefore={prefixSelector} required value={this.state.fields.phone} id="phone" allowClear onChange={this.handleChangeInput} />
            </Form.Item>
            {!this.state.otp &&
              <Form.Item label="Password" name="password" rules={[{
                required: true, message: 'Please input Password!',
              }]}>
                <Input.Password placeholder="Enter password " required value={this.state.fields.password} id="password" allowClear onChange={this.handleChangeInput} />

              </Form.Item>
            }
            {this.state.otp &&
              <Form.Item label="OTP" name="otp" rules={[{
                required: true, message: 'Please input OTP!',
              }]}>
                <Input.Password placeholder="Enter OTP " required value={this.state.fields.code} id="code" allowClear onChange={this.handleChangeInput} />

              </Form.Item>

            }
            <div className="form-actions flex items-center justify-between">
              <Button
                type="primary"
                className="width-150 mr-4"
                htmlType="submit" disabled={this.state.loading || false}
              >
                {this.state.otp ? "Submit OTP" : "Login"}
              </Button>
              <Button
                type="secondary" disabled={this.state.loading || false}
                className="width-150 mr-4"
                onClick={this.sendOTP}
              >
                {this.state.otp ? "Resend OTP" : "Send OTP"}
              </Button>
            </div>
            <div id="recaptcha-container">
              <div id="recaptcha"></div>
            </div>
          </Form>
          {this.state.loading &&
            <div id="loading" className="text-center" style={{ padding: 2, fontStyle: "italic" }}>Please wait, Sending OTP....</div>}
          <div className={styles.register_btn}>
            {this.state.otp && <><a
              type="secondary"
              className="width-150 mr-4"
              onClick={() => this.setState({ otp: false, loading: false })}
            ><u>
                Use Password Instead</u>
            </a><br /></>
            }

            <Link to="/society/register"
              className="width-150 mr-4">Register New Society!
            </Link>
          </div>

        </Card>
        <aside className="_social-links">
          <ul className="_links-list">
            <li className="_social-link"><a href="./about.html" target="_blank">About Us</a></li>
            <li className="_social-link"><a href="./services.html" target="_blank">Services</a></li>
            <li className="_social-link"><a href="./services.html" target="_blank">Plans</a></li>
            <li className="_social-link"><a href="./contact.html" target="_blank">Contact Us</a></li>
          </ul>
        </aside>
      </div>
    )
  }
}

const mapStateToProps = (state) => {

  return { login: state.user }
}

export default connect(mapStateToProps, { loginData })(Login)

