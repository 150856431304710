import actions from './action'

const initialState = {
    data: [],
    societyList : [],
    selectedSociety : ""
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_NOTIFICATION_DATA:
            return { ...state, data: action.payload, error: false }
        case actions.GET_SOCIETY_LIST:
                return { ...state, societyList: action.payload, error: false }
        case actions.SET_SOCIETY_ID:
                return { ...state, selectedSociety: action.payload, error: false }
        default:
            return state
    }
}