import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import Loader from '../components/LayoutComponents/Loader'
import PublicLayout from './Public'
import LoginLayout from './Login/index'
import MainLayout from './Main'
import { checkLoginData } from "../pages/user/login/redux/method"
import { notification } from 'antd'
import qs from 'qs'
const Layouts = {
  public: PublicLayout,
  login: LoginLayout,
  main: MainLayout,
}

@withRouter
@connect(({ user }) => ({ user }))
class IndexLayout extends React.PureComponent {

  constructor(props) {
    super(props)
    this.query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  }

  previousPath = ''

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { prevLocation } = prevProps
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  componentDidMount() {

    if (Object.keys(this.props.user).length == 0 && localStorage.getItem(window.SITE_CONFIG.STORAGE.USER && !this.query.page)) {
      this.props.dispatch(checkLoginData())
    }
  }

  render() {
    const {
      children,
      location: { pathname, search },
      user,
    } = this.props

    // NProgress Management
    const currentPath = pathname + search
    if (currentPath !== this.previousPath) {
      NProgress.start()
    }

    setTimeout(() => {
      NProgress.done()
      this.previousPath = currentPath
    }, 300)

    // Layout Rendering
    const getLayout = () => {
      if (pathname === '/') {
        return 'public'
      }
      if (pathname === '/society/register') {
        return 'login'
      }
      if (/^\/user(?=\/|$)/i.test(pathname)) {
        return 'login'
      }
      return 'main'
    }

    const Container = Layouts[getLayout()]
    const isUserAuthorized = user.authorized
    const isUserLoading = user.loading
    const isLoginLayout = getLayout() === 'login'
	  const isAdminOrSuper = (user.user && (user.user.role == window.SITE_CONFIG.USER_ROLE.SUPER_ADMIN || user.user.role == window.SITE_CONFIG.USER_ROLE.ADMIN || user.user.role == window.SITE_CONFIG.USER_ROLE.NURSE)?true:true);

    const BootstrappedLayout = () => {
      // show loader when user in check authorization process, not authorized yet and not on login pages
      if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
        return <Loader />
      }
      // redirect to login page if current is not login page and user not authorized
      if (!isLoginLayout && !isUserAuthorized) {
        return <Redirect to="/user/login" />
      } else if (isUserAuthorized && !isAdminOrSuper) {
		    localStorage.removeItem(window.SITE_CONFIG.STORAGE.USER)
			window.location.reload(false);
        return <Redirect to="/user/login" />

      }
      // redirect to main dashboard when user on login page and authorized
      if (isLoginLayout && isUserAuthorized && isAdminOrSuper) {
        // if ((!this.props.user.firstName || !this.props.user.gender) && !this.query.page) {
        //   if (this.props.user.features && !this.props.user.features.length) {
        //     notification.open({ message: "Purchase Plan", description: "Please purchase any plan to see the dashboard" })
        //     return <Redirect to="/products" />
        //   }
        //   notification.open({ message: "Update Profile", description: "Please update your profile data to access dashboard" })
        //   return <Redirect to="/dashboard/profile" />
        // }
        return <Redirect to="/dashboard/home" />

      }
	 
      // in other case render previously set layout
      return <Container>{children}</Container>
    }

    return (
      <Fragment>
        <Helmet titleTemplate="Samiti | %s" title="" />
        {BootstrappedLayout()}
      </Fragment>
    )
  }
}

export default IndexLayout
