import React from 'react'
import Loadable from 'react-loadable'
import Loader from './components/LayoutComponents/Loader'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => < Loader / > ,
  })

export const getRoutes = (user) => {
  const routes = [
    // System Pages
    {
      path: '/user/app',
      component: loadable(() => import('./pages/user/app.jsx')),
      exact: true,
    },
    {
      path: '/user/login',
      component: loadable(() => import('./pages/user/login/loginPage')),
      exact: true,
    },

    {
      path: '/dashboard/home',
      exact: true,
      component: loadable(() => import('./pages/home')),
    },
    {
      path: '/members',
      exact: true,
      breadcrumb: ["Members"],
      component: loadable(() => import('./pages/member/index.jsx')),
    },
    {
      path: '/members/addMember',
      exact: true,
      breadcrumb: ["members", "Add Member"],
      component: loadable(() => import('./pages/member/addMember.jsx')),
    },
    {
      path: '/members/:id/edit',
      exact: true,
      breadcrumb: ["Members", "Detail"],
      component: loadable(() => import('./pages/member/memberDetail.jsx')),
    },
    {
      path: '/transactions',
      exact: true,
      breadcrumb: ["Transactions"],
      component: loadable(() => import('./pages/transactions/transactions.jsx')),
    },
    {
      path: '/accounts',
      exact: true,
      breadcrumb: ["Accounts"],
      component: loadable(() => import('./pages/accounts/accounts.jsx')),
    },
    {
      path: '/society/register',
      exact: true,
      breadcrumb: ["Society", "Register"],
      component: loadable(() => import('./pages/society/society.jsx')),
    },
    {
      path: '/loans',
      exact: true,
      breadcrumb: ["Loans", "Loan Out"],
      component: loadable(() => import('./pages/loans/index.jsx')),
    },
    {
      path: '/societyBalance',
      exact: true,
      breadcrumb: ["Society Balance", "Society Balance"],
      component: loadable(() => import('./pages/society/societyDetail.jsx')),
    },
    {
      path: '/register',
      exact: true,
      breadcrumb: ["Register", "Accounts Register"],
      component: loadable(() => import('./pages/accounts/accounts-register.jsx')),
    },
    {
      path: '/banks',
      exact: true,
      breadcrumb: ["Banks"],
      component: loadable(() => import('./pages/banks/index.jsx')),
    },
    {
      path: '/banks/addBank',
      exact: true,
      breadcrumb: ["banks", "Add Bank"],
      component: loadable(() => import('./pages/banks/addBank.jsx')),
    },
    {
      path: '/member/resetPassword',
      exact: true,
      component: loadable(() => import('./pages/user/forgot/resetPassword.jsx')),
    },

  ];

  return routes;
}
