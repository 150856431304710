import actions from './action'

const initialState = {
    data: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_STATS:
            return { ...state, 
                yourBalance : action.payload.yourBalance,
                yourLoan : action.payload.yourLoan,
                societyAvailable : action.payload.societyAvailable,
                societyLoan : action.payload.societyLoan,
                bankAvailable : action.payload.bankAvailable,
                lastUpdate : action.payload.lastUpdate,
                membersCount : action.payload.membersCount,
                error: false }

        default:
            return state
    }
}