import React from 'react'
import HomeMenu from './HomeMenu'
import ProfileMenu from './ProfileMenu'
import { connect } from 'react-redux'
import styles from './style.module.scss'
@connect(({ user }) => ({ user }))
class TopBar extends React.Component {
  render() {
    const { user } = this.props
    return (
      <ul className={styles.topbar}>
        {window.innerWidth>600 && <li className={"font-semibold text-primary capitalize text-xl"}>Welcome {user.user?user.user.firstName: 'Guest'}
        </li>}
        <li className={styles.homeMenu}>  
          <HomeMenu />
        </li>
        <li>
          <div id="google_translate_element"></div>
          <script type="text/javascript"
    src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
        </li>
        <li className={styles.homeMenu}> 
        <ProfileMenu />
      </li>
      </ul>
    )
  }
}

export default TopBar
